<template>
 <div class="container-fluid stripe-bg px-0 pw-cont">
    <bg-slide></bg-slide>
    <home-btn/>
    <div class="login-container relative" style="">

        <div class="circle-login-before "></div>
        <b-aspect :aspect="1" class="circle-login primary--text">
          <b-img :src="require('@/assets/logo.svg')" style="width:40%" class="logo-circle"/>
        <validation-observer v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(forgotSubmit)" class="mt-2">
            <h2 class="text-center mb-4 white--text">{{ $t('new_password') }}</h2>
            <b-form-group>
              <validation-provider :name="$t('password')" rules="required|min:4" v-slot="{ errors }" vid="newpassword">
                <b-form-input type="password"
                              name="password"
                              :placeholder="$t('password')"
                              v-model="credentials.password"
                              :size="windowW > 768 ? 'lg' : 'md'"
                              :class="{'form-control': true, 'is-danger': errors.length>0 }"
                              autofocus="">
                </b-form-input>
                <small v-show="errors.length>0" class="text-danger is-danger">
                  <i class="fa fa-warning"></i> {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider :name="$t('password_confirm')" rules="required|confirmed:newpassword" v-slot="{ errors }">
                <b-form-input type="password"
                              id="password" pill
                              name="password"
                              :placeholder="$t('confirm_password')"
                              v-model="credentials.passwordConfirm"
                              :size="windowW > 768 ? 'lg' : 'md'"
                              :class="{'form-control': true, 'is-danger': errors.length>0 }">
                </b-form-input>
                <small v-show="errors.length>0" class="text-danger is-danger">
                  <i class="fa fa-warning"></i> {{ errors[0] }}
                </small>
              </validation-provider>


            </b-form-group>
<div class="text-center">
            <b-btn type="submit" pill variant="primary"
                    class="btn btn-warning mt-3"
                    :size="windowW > 768 ? 'lg' : 'md'"
                    :disabled="forgotSubmitted">
              {{ $t('send') }}
            </b-btn>
</div>

          </b-form>
        </validation-observer>
        </b-aspect>
      </div>
  </div>
</template>

<script type="text/babel">
import Vue from 'vue';
import { apiClient } from '@/util/resources';
import bgSlide from '@/components/bgSlide';
import homeBtn from '@/components/homeBtn';
export default Vue.extend({
  data() {
    return {
      credentials: {
        password: '',
        passwordConfirm: '',

      },
       windowW: 0,
      forgotSubmitted: false,
    };
  },
  mounted(){
this.getSizes();
window.addEventListener('resize', this.getSizes);
},
components:{bgSlide, homeBtn},
  methods: {
    getSizes() {
      this.windowW = window.innerWidth;
    },
    forgotSubmit() {
      this.forgotSubmitted = true;
    const this_=this;

            //formData.append()
            this.$store.commit('loader/show');
      apiClient.post(`/api/users/forgotpwd/${this_.$router.currentRoute.params.token}`, this.credentials).then(response => {

            this.$root.$bvToast.toast(this_.$t('pass_success'), {
            title: this_.$t('success'),
            variant: "success",
            solid: true,
            toaster: "b-toaster-top-center",
          });

          this.$router.push({ name: 'home' });
          this.$store.commit('loader/hide');

      }).catch(error => {
        this.$root.$bvToast.toast(error.message, {
          title: this.$i18n.t('_error'),
          variant: 'danger',
          solid: true,
          noAutoHide: true,
          toaster: 'b-toaster-top-center',
        });
        this.$store.commit('loader/hide');
        this.forgotSubmitted = false;
      });

    },
  },

});
</script>
<style lang="scss">
.pw-cont{
  min-height: 100vh !important;
  .login-container{
    z-index: 10;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
.circle-login{
     position: relative;
  width: 600px;
  height: 600px;
  margin: auto;
  padding: 5%;
  max-width: 100vw;
  max-height: 100vw;
  align-items: center;
    .form-control-lg {

    border-radius: 2rem;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #94c11f;
    background-color: #94c11f;
}
a {
    color: #699004;

}
 &:before {
    content: "";
    border-radius: 50%;
    /* padding: 20%; */
    z-index: 4;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: #313131e3;

    border: 15px solid #505050;
  }
}


.logo-circle{
  width: 40%;
    margin: auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -3%;
    margin-bottom: 4%;
}
@media (max-width:768px){
.circle-login {
    padding: 0 9vw;
    box-sizing: border-box;
    max-width: 100%;
    &:before{
      border-width:2vw;
    }
    h2{
      font-size: 5vw;
    }
}

}
</style>
